import gql from "graphql-tag";

export default function (providerId, settings) {

  let query = `
      {
     
      
        orders(
          sorting: {
            sortBy: "${settings.sorting.sortBy}"
            direction: ${settings.sorting.direction}
          }
          filter: {
            status: COMPLETED,
            service_locations_ids: "${settings.filters.service_locations_ids}"
          }
        )
        {
          id
          price{
            currency
            amount
        }
        createdAt
        }
      
    }`

  return gql`${query}`;
}