import Router from "vue-router";
import Vue from "vue";
import mainOidc from "~/auth";

Vue.use(Router);

let routes = [
  {
    path: "/",
    name: "login",
    redirect: {
      name: 'dashboard',
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    redirect: {
      name: 'statistics',
    },
  },
  {
    path: "/statistics",
    name: "statistics",
    component: () => import("~/templates/Statistics"),
    meta: {
      authName: mainOidc.authName
    }
  },
  {
    // Redirect the older
    path: '/onboarding',
    redirect: to => {
      if(to.query.providerID === undefined) return '404'
      return to.path + '/' + to.query.providerID
    }
  },
  {
    path: "/onboarding/:providerId",
    name: "onBoarding.initial",
    props: true,
    component: () => import("~/templates/OnBoarding"),
    children: [
      {
        component: () => import('~/templates/onBoarding/OwnerFields'),
        name: 'onBoarding.step1',
        path: 'step-1',
        props: true,
      },
      {
        component: () => import('~/templates/onBoarding/RequiredDocuments'),
        name: 'onBoarding.step2',
        path: 'step-2',
        props: true,
      },
      {
        component: () => import('~/templates/onBoarding/ShareholderFields'),
        name: 'onBoarding.step3',
        path: 'step-3',
        props: true,
      },
      {
        component: () => import('~/templates/onBoarding/BankFields'),
        name: 'onBoarding.step4',
        path: 'step-4',
        props: true,
      },
      {
        component: () => import('~/templates/onBoarding/Completed'),
        name: 'onBoarding.step5',
        path: 'step-5',
        props: true,
      },
    ],
    meta: {
      authName: mainOidc.authName
    }
  },

  {
    name: "orders.index",
    path: "/orders",
    component: () => import("~/templates/Orders_index"),
    meta: {
      authName: mainOidc.authName
    }
  },
  {
    name: "pricelist",
    path: "/pricelist",
    component: () => import("~/templates/ServiceLocations"),
    meta: {
      authName: mainOidc.authName
    }
  },
  {
    name: "servicePriceList",
    path: "/servicepricelist",
    component: () => import("~/templates/ServicePricelist"),
    meta: {
      authName: mainOidc.authName
    }
  },
  {
    name: "orders.arrivalsDepartures",
    path: "/orders/arrivals-departures",
    component: () => import("~/templates/Orders_arrivalsDepartures"),
    meta: {
      authName: mainOidc.authName
    }
  },
  {
    name: "orders.show",
    path: "/orders/:id",
    component: () => import("~/templates/Orders_show"),
    props: true,
    meta: {
      authName: mainOidc.authName
    }
  }
];

let router = new Router({
  scrollBehavior: function() {
    return {
      x: 0,
      y: 0
    };
  },
  linkExactActiveClass: "exact-active",
  linkActiveClass: "active",
  routes: routes,
  base: process.env.BASE_URL,
  mode: "history"
});

mainOidc.useRouter(router);

router.addRoutes([
  {
    path: '/*',
    component: () => import('~/templates/Errors_notFound'),
    name: 'errors.not_found',
  }
]);

export default router;
