import Vuex from 'vuex';
import Vue from 'vue';
import {getField, updateField} from "vuex-map-fields";

Vue.use(Vuex);

export const registrationStore = {
  namespaced: true,
  state: {
    firstTime: true,
    totalSteps: [
    {
      id: 1,
      stateType: "error",
      message: "",
    },
    {
      id: 2,
      stateType: "error",
      message: "",
    },
    {
      id: 3,
      stateType: "error",
      message: "",
    },
    {
      id: 4,
      stateType: "error",
      message: "",
    },
    {
      id: 5,
      stateType: "error",
      message: "",
    }]
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
  }
}
