import { createOidcAuth, SignInType } from "vue-oidc-client";
import Vue from "vue";
import {WebStorageStateStore} from "oidc-client";

const fullUrl = window.location;
const appRootUrl = `${fullUrl.protocol}//${fullUrl.host}${process.env.BASE_URL}`;

const mainOidc = createOidcAuth("main", SignInType.Window, appRootUrl, {
  authority: process.env.VUE_APP_AUTHORITY,
  client_id: process.env.VUE_APP_CLIENT_ID,
  response_type: "code",
  scope: "offline email openid providers",
  redirect_uri: `${appRootUrl}callback`,
  post_logout_redirect_uri: `${appRootUrl}callback`,
  userStore: new WebStorageStateStore({ store: window.localStorage })
});

Vue.prototype.$oidc = mainOidc;

export default mainOidc;
