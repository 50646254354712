import gql from "graphql-tag";

export default function (service_locations_ids) {

  return gql`
    {
      arrivalsToday: 
        
        orders(
          filter: {
            status: COMPLETED
            period: {
              direction: Arrival
              type: Today
            }
            service_locations_ids: "${service_locations_ids}"
          }
        ) {
          price{
                currency
                amount
            }

        },
   

      departuresToday: 

        orders(
          filter: {
            status: COMPLETED
            period: {
              direction: Departure
              type: Today
            }
            service_locations_ids: "${service_locations_ids}"
          }
        ) {
          id
          mobianID
          price{
                currency
                amount
            }
          mobianID
          
        

      },
      arrivalsTomorrow: 
        
        orders(
          filter: {
            status: COMPLETED
            period: {
              direction: Arrival
              type: Tomorrow
            }
            service_locations_ids: "${service_locations_ids}"
          }
        ) {
          price{
                currency
                amount
            }
          mobianID
          
        },
   

      departuresTomorrow: 
        
        orders(
          filter: {
            status: COMPLETED
            period: {
              direction: Departure
              type: Tomorrow
            }
            service_locations_ids: "${service_locations_ids}"
          }
        ) {
          price{
                currency
                amount
            }
          mobianID
          
        }

      
    }`;
}