import gql from "graphql-tag";

export default function (service_locations_ids, type) {

  return gql`
    {
      arrivals:
        
        orders(
          filter: {
            status: COMPLETED
            period: {
              direction: Arrival
              type: ${type}
            }
            service_locations_ids: "${service_locations_ids}"
          }
        ) {
          price{
                currency
                amount
            }
          mobianID
          
        
        },


      departures: 
       
        orders(
          filter: {
            status: COMPLETED
            period: {
              direction: Departure
              type: ${type}
            }
            service_locations_ids: "${service_locations_ids}"
          }
        ) {
          price{
                currency
                amount
            }
          mobianID
          
        }

      
    }`;
}