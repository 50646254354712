import GetProviderOrdersForListQuery from './providers/GetProviderOrdersForList'
import GetProviderOrders, {providerOrderSettings} from "./providers/GetProviderOrders"
import mixins from '~/mixins'

import CountProviderOrdersForPeriodType from "./providers/CountProviderOrdersForPeriodType";
import CountProviderOrdersForPeriodRange from "./providers/CountProviderOrdersForPeriodRange";
import CountProviderOrdersTodayTomorrow from "./providers/CountProviderOrdersTodayTomorrow";
import GetProviderOrdersVouchers from "@/graphql/providers/GetProviderOrdersVouchers";
import GetProviderOrderStatistics from "./providers/GetProviderOrderStatistics";
import GetProviderOrdersCount from "./providers/GetProviderOrdersCount"
import GetProviderPagesCount from "./providers/GetProviderPagesCount"
import GetProviderServiceLocations from "./providers/GetProviderServiceLocations"


export default {
  methods: {

    fetchProfile () {

     let  variables= {
      email: this.$oidc.userProfile.email
     }
      return this.$apollo.query({
        query: require('~/graphql/GetProfile.gql'),variables,
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
      })
    },

    // fetchProvider (providerId) {
    //
    //   let variables = {
    //     id: providerId
    //   }
    //
    //   return this.$apollo.mutate({
    //     mutation: require('~/graphql/providers/GetProviderByID.gql'), variables, fetchPolicy: 'no-cache'
    //   })
    // },

    fetchOrder (orderId) {

      let variables = {
        id: orderId
      }

      return this.$apollo.mutate({
        mutation: require('~/graphql/orders/GetOrder.gql'), variables, fetchPolicy: 'no-cache'
      })
    },

    fetchOrdersForSidebar (providerId) {
      return this.$apollo.query({
        query: GetProviderOrdersForListQuery(providerId), fetchPolicy: 'no-cache'
      })
    },

    fetchOrders (providerId, settings = null) {
      if(settings === null ) settings = mixins.methods.deepClone(providerOrderSettings);

      return this.$apollo.query({
        query: GetProviderOrders(providerId, settings)
      })
    },
    fetchServiceLocations (providerId,date) {
      return this.$apollo.query({
        query: GetProviderServiceLocations(providerId,date),fetchPolicy: 'no-cache'
      })
    },
    fetchOrdersCount ( settings = null) {
      if(settings === null ) settings = mixins.methods.deepClone(providerOrderSettings);

      return this.$apollo.query({
        query: GetProviderOrdersCount( settings)
      })
    },
    fetchPagesCount (providerId, settings = null) {
      if(settings === null ) settings = mixins.methods.deepClone(providerOrderSettings);

      return this.$apollo.query({
        query: GetProviderPagesCount(providerId, settings)
      })
    },


    fetchOrderStatistics (providerId, settings = null) {
      if(settings === null ) settings = mixins.methods.deepClone(providerOrderSettings);

      return this.$apollo.query({
        query: GetProviderOrderStatistics(providerId, settings)
      })
    },

    generateVouchersDownload(providerId, settings) {
      return this.$apollo.query({
        query: GetProviderOrdersVouchers(providerId, settings)
      })
    },

    countOrderForPeriodRange (service_locations_ids, from, to) {
      return this.$apollo.query({
        query: CountProviderOrdersForPeriodRange(
          service_locations_ids,
          mixins.methods.dateFormatQuery(from),
          mixins.methods.dateFormatQuery(to),
          
        )
      })
    },

    countOrdersForPeriodType (service_locations_ids, type) {
      return this.$apollo.query({
        query: CountProviderOrdersForPeriodType(service_locations_ids, type)
      })
    },

    countOrdersTodayTomorrow (countOrdersTodayTomorrow) {
      return this.$apollo.query({
        query: CountProviderOrdersTodayTomorrow(countOrdersTodayTomorrow)
      })
    }

  }
}

