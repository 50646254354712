import Vuex from 'vuex';
import Vue from 'vue';
import { getField, updateField } from 'vuex-map-fields';

Vue.use(Vuex);

export const providerStore = {
  namespaced: true,
  state: {
    formData: {
      owner: {
        gender: '',
        firstName: "",
        lastName: "",
        birthdate: "",
        email: "",
      },
      administrativeContact: {
        gender: '',
        firstName: "",
        lastName: "",
        birthdate: "",
        email: "",
      },

      email: "",
      legalName: "",
      locale: "",
      businessType: "",
      legalEntity: "",
      businessStructure: "",
      currency: "",
      cocID: "",
      vatID: "",
      address: "",
      zipcode: "",
      city: "",
      country: "",

      bankAccount: "",
      bankCountry: "",
      swift: "",

      cardIdFront: "",
      cardIdBack: "",
      documents: [],
      cardIdRepresentative: null,
      articleOfAssociation: null,
      certificateOfIncorporation: null,
      identificationStatus: null,
      uboStatementStatus: "",
      ubo: [],
    },
    hipayAgreed: false,
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
  },
}