import Vuex from 'vuex';
import Vue from 'vue';
import { getField, updateField } from 'vuex-map-fields';

Vue.use(Vuex);

export const bankStore = {
  namespaced: true,
  state: {
    formData: {
      file: null,
      bankCountry: "",
      bankName: "",
      bankAddress: "",
      bankZipCode: "",
      bankCity: "",
      iban: "",
      swift: "",
      abaNum: "",
      bsbNum: "",
      bankNum: "",
      acctNum: "",
      transitNum: "",
    },
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
  },
}