import Vue from 'vue'
import Vuex from 'vuex'

// HiPay Registration
import {providerStore} from "~/store/hipay/providerStore";
import {userStore} from "~/store/hipay/userStore";
import {uboStore} from "~/store/hipay/uboStore";
import {accountStore} from "~/store/hipay/accountStore";
import {fileStore} from "~/store/hipay/fileStore";
import {bankStore} from "~/store/hipay/bankStore";
import {registrationStore} from "~/store/hipay/registrationStore";

// Provider Dashboard
import {providerState} from "~/store/provider/providerState";

import {createHelpers} from "vuex-map-fields";
import {getField, updateField} from 'vuex-map-fields';

Vue.use(Vuex)

export const { mapFields: mapProviderFields } = createHelpers({
  getterType: `providerStore/getField`,
  mutationType: `providerStore/updateField`,
});

export const { mapFields: mapFileFields } = createHelpers({
  getterType: `fileStore/getField`,
  mutationType: `fileStore/updateField`,
});

export const { mapFields: mapUserFields } = createHelpers({
  getterType: `userStore/getField`,
  mutationType: `userStore/updateField`,
});

export const { mapFields: mapUboFields } = createHelpers({
  getterType: `uboStore/getField`,
  mutationType: `uboStore/updateField`,
});

export const { mapFields: mapBankFields } = createHelpers({
  getterType: `bankStore/getField`,
  mutationType: `bankStore/updateField`,
});

export const { mapFields: mapRegistrationFields } = createHelpers({
  getterType: `registrationStore/getField`,
  mutationType: `registrationStore/updateField`,
});

// Provider
export const { mapFields: mapProviderStateFields } = createHelpers({
  getterType: `providerState/getField`,
  mutationType: `providerState/updateField`,
});

export default new Vuex.Store({

  state: {
    step: null,
    drawer: false
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    TOGGLE_SITE_NAV: (state, payload) => {
      state.drawer = payload
    }
  },

  modules: {
    registrationStore,
    // Registration
    userStore,
    uboStore,
    providerStore,
    fileStore,
    accountStore,
    bankStore,
    // ProviderDashboard
    providerState,

  }
})

