<template>
  <d-navbar type="dark" class="top-navigation  u-bg-primary--500">

    <div class="top-navigation__left">
      <div v-if="showMenuIcon" class="top-navigation__menu  sp-mr-2" @click="$store.commit('TOGGLE_SITE_NAV', !$store.state.drawer)">
        <svg viewBox="0 0 100 80" width="20" height="20">
          <rect fill="currentColor" width="100" height="10"></rect>
          <rect fill="currentColor" y="30" width="100" height="10"></rect>
          <rect fill="currentColor" y="60" width="100" height="10"></rect>
        </svg>
      </div>

      <div class="top-navigation__logo">
        <strong class="top-navigation__name">ROOSH</strong><span class="u-color-roosh--600">.</span>
        <span class="top-navigation__suffix  sp-ml-1">{{ $t('nav.provider_dashboard') }}</span>
      </div>
    </div>

    <div class="top-navigation__right">

      <language-menu />

      <button class="btn  top-navigation__button" v-d-toggle.user-actions>
        <i class="material-icons  sp-mr-1">account_circle</i>
        <span class="top-navigation__user-name">{{ $oidc.userProfile.email }}</span>
        <span class="dropdown-toggle  sp-ml-1"></span>
      </button>

      <d-collapse id="user-actions" class="dropdown-menu dropdown-menu-small">
        <d-dropdown-item class="text-danger">
          <div @click="userSignOut">
            <i class="material-icons">&#xE879;</i> {{ $t('nav.log_out') }}
          </div>
        </d-dropdown-item>
      </d-collapse>
    </div>

  </d-navbar>
</template>

<script>

import mixins from '~/mixins';
import LanguageMenu from "./LanguageMenu";

export default {
  name: "TopNavigation",
  components: {LanguageMenu},
  mixins: [mixins],

  methods: {
    userSignOut() {
      this.$oidc.signOut();
    }
  },

  computed: {
    showMenuIcon() {
      return !this.isNamedRouteActive('onBoarding.initial');
    }
  }
};
</script>