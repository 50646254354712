import Vue from "vue";
import App from "~/App.vue";
import store, {mapProviderStateFields, mapUserFields} from "~/store";
import router from "~/router";
import Shards from 'shards-vue';
import { createProvider } from "~/vue-apollo";
import mainOidc from "~/auth";
import { setupAndGetI18n } from "~/i18n";
import mixins from '~/mixins';
import graphqlCalls from '~/graphql'

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole } from '@sentry/integrations';

export const i18n = setupAndGetI18n(Vue);

Vue.config.productionTip = false;
Vue.mixin(mixins);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  logErrors: true,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "provider.roosh.online", "provider.staging.roosh.online", /^\//],
    }),
    new CaptureConsole({
      levels: ['error']
    })
  ],

  // To set a uniform sample rate
  tracesSampleRate: 0.4
});

// Import base styles (Bootstrap and Shards)
import 'bootstrap/dist/css/bootstrap.css'
import 'shards-ui/dist/css/shards.css'
import '~/scss/styling.scss'
Shards.install(Vue);

mainOidc.startup().then(ok => {
  if (ok) {

    new Vue({
      router,
      store,
      i18n,
      apolloProvider: createProvider(),
      mixins: [graphqlCalls],

      computed: {
        ...mapProviderStateFields([
          'is_loaded',
          'active_provider_index',
          'providers',
          'providerSideBarOrders',
        ]),
        ...mapUserFields([
          'user'
        ]),
        authenticated : function () {
          return this.$oidc.isAuthenticated;
        }
      },

      methods: {

        fetchFromApi() {

          const self = this;

          this.fetchProfile().then(async (response) => {

            self.is_loaded = true
            self.user = response.data.profile

            // document.documentElement.lang = self.user.locale;
            // localStorage.setItem("language", self.user.locale);
            // i18n.locale = self.user.locale

            self.providers = response.data.userForProviderDashboard.providers

            self.providers.sort(function(a, b) {
              if(a.name < b.name) { return -1; }
              if(a.name > b.name) { return 1; }
              return 0;
            });

            if(self.providers !== null && self.providers.length > 0) {

              let activeProviderIndex = window.localStorage.getItem('activeProviderIndex');

              if(!activeProviderIndex) activeProviderIndex = 0
              else activeProviderIndex = parseInt(activeProviderIndex)

              // Prevent crash when a provider is remove from user
              if(activeProviderIndex >= self.providers.length) activeProviderIndex = 0

              this.active_provider_index = activeProviderIndex
            }

          })
        },
      },

      created() {
        if(!this.$oidc.isAuthenticated) return
        this.fetchFromApi();
      },

      watch: {
        authenticated: function (val) {
          if(!val) return;
          this.fetchFromApi();
        },
        active_provider_index: function (index) {

          const self = this;
          this.providerSideBarOrders.orders_loaded = false

          // this.fetchOrdersForSidebar(self.providers[index].id).then((data) => {
          //   self.providerSideBarOrders.arrivals = data.data.arrivals.orders ?? []
          //   self.providerSideBarOrders.departures = data.data.departures.orders ?? []
          //   self.providerSideBarOrders.orders_loaded = true
          // })

        }
      },

      render: createElement => createElement(App)
    }).$mount("#app");
  }
});