import Vuex from 'vuex';
import Vue from 'vue';
import {getField, updateField} from "vuex-map-fields";

Vue.use(Vuex);

export const fileStore = {
  namespaced: true,
  state: {
    ID: null,
    ChamberOfCommerce: null,
    ArticlesOfAssociation: null,
    docID: false,
    docCOC: false,
    docAOA: false,
    documentsToValidate: [], // 1=ID, 2=COC, 3=AOA
    validatedDocuments: []
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
  }
}
