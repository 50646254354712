<template>
    <div class="app__sign-in" >

      <d-card style="max-width: 340px">
        <d-card-body :title="$t('login.title')">
          <p>{{ $t('login.message') }}</p>
          <p v-if="showAdditionalMessage">{{ $t('login.additional_message') }}</p>
        </d-card-body>
      </d-card>

    </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      showAdditionalMessage: false
    }
  },
  created() {
    setTimeout(() => {
      this.showAdditionalMessage = true;
    },10000)

  }
}
</script>