import gql from "graphql-tag";

export default function ( service_locations_ids, from, to) {

  return gql`
    {
      arrivals: 
        
        orders(
          filter: {
            status: COMPLETED
            period: {
              direction: Arrival
              from: "${from} 00:00:00"
              to: "${to} 23:59:59"
            }
            service_locations_ids: "${service_locations_ids}"
          }
        ) {
          price{
                currency
                amount
            }
          mobianID
          
        
        

      },
      departures: 
        
        orders(
          filter: {
            status: COMPLETED
            period: {
              direction: Departure
              from: "${from} 00:00:00"
              to: "${to} 23:59:59"
            }
            service_locations_ids: "${service_locations_ids}"
          }
        ) {
          price{
                currency
                amount
            }
          mobianID
          
        
        

      }
    }`;
}