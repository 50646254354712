import Vuex from 'vuex';
import Vue from 'vue';
import { getField, updateField } from 'vuex-map-fields';

Vue.use(Vuex);

export const uboStore = {
  namespaced: true,

  state: {
    formData: {
      // Person information
      title: "",
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      placeOfBirth: "",
      countryOfBirth: "",
      nationality: "",
      // Business address
      address: "",
      city: "",
      zipcode: "",
      country: "",
      // Company information
      shares: "0",
      position: "",
      decisionMaker: false,
      politicalyExposed: false,
      // ID information
      idNumber: "",
      cardIdFront: null,
      cardIdBack: null,
      proofOfAddress: null,
    },
    shareholders: [],
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    updateShareholder (state, payload) {
      state.shareholders.push(payload)
    }
  },

  actions: {
    addShareHolder({commit, state}) {
      commit('updateShareholder', state.formData)
    }
  }

}