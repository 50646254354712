import Vuex from 'vuex';
import Vue from 'vue';
import { getField, updateField } from 'vuex-map-fields';

Vue.use(Vuex);

export const userStore = {
  namespaced: true,
  state: {
    user: null,
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
  },

  actions: {

  }

}