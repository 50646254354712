import gql from "graphql-tag";
import mixins from "~/mixins"

export default function (providerId, settings) {

  let query =
  `pagesCount(
    pagination: {
      limit: ${settings.pagination.limit},
      page: ${settings.pagination.page}
    }
    filters:{
   `
    query += `providerId:"${providerId}"\n`
    if(settings.filters.email !== '') query += `email: "${settings.filters.email}"\n`
    if(settings.filters.reference !== '') query += `reference: "${settings.filters.reference}"\n`
    if(settings.filters.licensePlate !== '') query += `licensePlate: "${settings.filters.licensePlate}"\n`
    if(settings.filters.status !== null) query += `status: ${settings.filters.status}\n`
  
    if(settings.filters.period.direction !== null || settings.filters.period.type !== null || settings.filters.period.from !== '' || settings.filters.period.to !== '') {
      query += `period: {\n`
  
      if(settings.filters.period.direction !== null) query += `direction: ${settings.filters.period.direction}\n`
      if(settings.filters.period.type !== null) query += `type: ${settings.filters.period.type}\n`
      if(settings.filters.period.from !== '') query += `from: "${ mixins.methods.dateFormatQuery(settings.filters.period.from)} 00:00:00"\n`
      if(settings.filters.period.to !== '') query += `to: "${ mixins.methods.dateFormatQuery(settings.filters.period.to)} 23:59:59"\n`

      query += `}\n`
    }
   `
  })`

  return gql`${query}`;
}

export let providerPagesCount = {
  pagination: {
    limit: 10,
    page: 1
  },
  filters: {
    email: '',
    reference: '',
    licensePlate: '',
    status: null,
    period: {
      direction: null,
      type: null,
      from: '',
      to: '',
    }
  },
}