module.exports = {
  methods: {

    equalObjects (object1, object2)
    {
      return JSON.stringify(object1) === JSON.stringify(object2);
    },

    priceFormat (price, currency) {

      let formatOptions = {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }

      if(currency) {
        formatOptions.style = 'currency';
        formatOptions.currency= currency;
      }

      return new Intl.NumberFormat('en-IN',formatOptions).format(price);
    },

    makeDayjsDate (date, timezone) {

      const dayjs = require("dayjs");
      const utcPlugin = require('dayjs/plugin/utc')
      const customFormat = require('dayjs/plugin/customParseFormat')
      const timezonePlugin = require('dayjs/plugin/timezone') // dependent on utc plugin
      dayjs.extend(utcPlugin)
      dayjs.extend(timezonePlugin)
      dayjs.extend(customFormat)

      let dateObject = null

      if(typeof date.getMonth === 'function') dateObject = date
      else {
        if (date.indexOf(' ') >= 0) dateObject = dayjs(date, "YYYY-MM-DD HH:mm:ss").toDate()
        else dateObject = new Date(Date.parse(date))
      }

      if(this.empty(timezone)) return dayjs(dateObject)
      return dayjs(dateObject).utc(true).tz(timezone)
    },

    dateFormat (date, timezone) {
      return this.makeDayjsDate(date, timezone).format('DD-MM-YYYY')
    },

    dateFormatQuery (date) {
      return this.makeDayjsDate(date).format('YYYY-MM-DD')
    },

    timeFormat (date, timezone) {
      return this.makeDayjsDate(date, timezone).format('HH:mm')
    },

    /**
     * Check if the given named route is currently active
     *
     * @param namedRoute
     */
    isNamedRouteActive (namedRoute) {
      return this.$route.name === namedRoute;
    },

    /**
     * Check if the active route name starts with is currently active
     *
     * @param namedRoute
     */
    activeRouteStartsWith (namedRoute) {
      if(this.$route.name === null) return false
      return this.$route.name.startsWith(namedRoute);
    },

    isset (value) {
      return typeof value !== 'undefined' && value !== null;
    },

    /**
     * Simple empty method for this does not exist in javascript
     *
     * @param value
     * @returns {boolean}
     */
    empty (value) {
      return typeof value === 'undefined' || value === null || value === '';
    },

    /**
     * Simple in_array method for this does not exist in javascript
     *
     * @param needle
     * @param haystack
     * @returns {boolean}
     */
    in_array (needle, haystack) {
      // return haystack.indexOf(needle) !== -1;
      // return haystack.includes(needle);
      return haystack.some(e => e == needle);
    },

    /**
     * Simple ucfirst method
     *
     * @param string
     * @returns string
     */
    ucfirst (string) {
      if(string === undefined) return string;
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    log (data) {
      console.log(data);
    },

    /**
     * Javascript version of the number_format method of PHP
     *
     * @param number
     * @param decimals
     * @param dec_point
     * @param thousands_sep
     * @returns {string}
     */
    number_format(number, decimals, dec_point, thousands_sep) {
      // Strip all characters but numerical ones.
      number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
      let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ' ' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? ',' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
          var k = Math.pow(10, prec);
          return '' + Math.round(n * k) / k;
        };
      // Fix for IE parseFloat(0.55).toFixed(0) = 0;
      s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
      }
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
      }
      return s.join(dec);
    },

    deepClone(value) {
      return JSON.parse(JSON.stringify(value));
    },

    statusLabel(status) {

      let theme = 'badge mr-1 ';
      if (status.toLowerCase() === 'pending') {
        theme = 'badge-warning';
      } else if (status.toLowerCase() === 'completed') {
        theme = 'badge-success';
      } else if (status.toLowerCase() === 'cancelled') {
        theme = 'badge-danger';
      } else if (status.toLowerCase()=== 'undetermined') {
        theme = 'badge-primary';
      }
      return theme;

    },

    runValidation (value, rules,) {

      const errorBucket = []

      for (let index = 0; index < rules.length; index++) {
        const rule = rules[index]
        const valid = typeof rule === 'function' ? rule(value) : rule

        if (valid === false || typeof valid === 'string') {
          errorBucket.push(valid || '')
        } else if (typeof valid !== 'boolean') {

          console.error(`Rules should return a string or boolean, received '${typeof valid}' instead`, this);
        }
      }

      return [errorBucket.length === 0, errorBucket];
    },

    removeDefaultGraphQLPrefix (errorLine) {
      return errorLine.replace('GraphQL error: ', '');
    },

    serviceIdToCodeName (id) {
      switch (id) {

        case '6dvgln4ofde':
          return 'shuttle';

        case '8ftpe6gpdvi':
          return 'valet';

        case 'hg49xy82wi':
          return 'walking';

        case 'lqbmkqreh':
          return 'taxi';

        default:
          return null;
      }
    }
  }
}