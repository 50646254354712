import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

export const accountStore = {
  namespaced: true,
  state: {
    code: null,
    message: '',
    email: '',
    status: null,
    accountId: null,
    userspaceId: null,
    errors: {}
  },

  getters: {
  },

  mutations: {
  },

  actions: {
  }

}