import gql from "graphql-tag";

export default function (providerId) {
  return gql`
    {
      arrivals: provider(id: "${providerId}") {
        id
        bookings(
          filter: {
            status: COMPLETED
            period: {
              direction: Arrival
              type: Today
            }
          }
          pagination: {
            limit: 4,
            page: 1
          }
          sorting: {
            sortBy: "from"
            direction: Ascending
          }
        ) {
          id
          journey{
            car{
              license_plate
            }
          }
          start
          end
          time_zone
        }
      },
      departures: provider(id: "${providerId}") {
        id
        bookings(
          filter: {
            status: COMPLETED
            period: {
              direction: Departure
              type: Today
            }
          }
          pagination: {
            limit: 4,
            page: 1
          }
          sorting: {
            sortBy: "to"
            direction: Descending
          }
        ) {
          id
          journey{
            car{
              license_plate
            }
          }
          start
          end
          time_zone
        }
      }
    }`
}