import gql from "graphql-tag";

export default function (providerId) {

  let query = `
      {
      provider(id: "${providerId}") {
        id
        currency
        serviceLocations {
          id
          parking_type_id
          
          services {
            id
            parking_type_id
            vendor_service_id
            
            mobian {
              name
              category
            }
            provider_id
            provider {
              id
            }
            enabled
            parking_capacity
            travel_time
            max_travelers
            terminals
            pricelists {
              id
              name
              available_from
              available_to
              currency
              nonbookable
              service_id
              rows {
                from
                to
                price
                extra
              }
            }
            min_booking_time
            covered
          }
        }


      }
    }`

  return gql`${query}`;
}