import VueI18n from "vue-i18n";

export function setupAndGetI18n(Vue) {

  Vue.use(VueI18n);

  let bootLocale = localStorage.getItem('language');

  if(bootLocale === undefined) {
    bootLocale = 'en_GB'
  }

  const i18n = new VueI18n({
    locale: bootLocale,
    fallbackLocale: "en_GB",
    fallbackRoot: false,
    silentTranslationWarn: true
  });

  i18n.setLocaleMessage("de", require("./i18n/de.json"));
  i18n.setLocaleMessage("en_GB", require("./i18n/en_GB.json"));
  i18n.setLocaleMessage("es", require("./i18n/es.json"));
  i18n.setLocaleMessage("fr", require("./i18n/fr.json"));
  i18n.setLocaleMessage("it", require("./i18n/it.json"));
  i18n.setLocaleMessage("nl", require("./i18n/nl.json"));
  i18n.setLocaleMessage("pt", require("./i18n/pt.json"));
  // i18n.setLocaleMessage("de_CH", require("./i18n/de_CH.json"));
  // i18n.setLocaleMessage("en_AU", require("./i18n/en_AU.json"));
  // i18n.setLocaleMessage("en_US", require("./i18n/en_US.json"));
  // i18n.setLocaleMessage("fr_BE", require("./i18n/fr_BE.json"));
  // i18n.setLocaleMessage("nl_NL", require("./i18n/nl.json"));
  // i18n.setLocaleMessage("nl_BE", require("./i18n/nl_BE.json"));
  // i18n.setLocaleMessage("pt_PT", require("./i18n/pt.json"));

  return i18n;
}
