<template>
  <main class="app">

    <login v-show="!$root.authenticated && !isErrorPage" />

    <top-navigation />
    <aside-navigation />

    <section class="app__main  u-bg-neutral--80">
      <router-view></router-view>
    </section>

  </main>
</template>

<script>

import TopNavigation from "./components/TopNavigation";
import AsideNavigation from "./components/AsideNavigation";
import Login from "@/templates/Login";
export default {
  name: "App",
  components: {AsideNavigation, TopNavigation, Login},

  computed: {

    isErrorPage() {
      if(this.$route.name === null) return false
      return this.$route.name.startsWith('errors.')
    }
  }

};
</script>
