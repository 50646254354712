<template>
  <div>
    <aside class="aside-navigation  u-bg-primary--500" :class="{ 'is-open': $store.state.drawer }">

      <div class="aside-navigation__top">

        <div class="aside-navigation__logo-name">
          <div class="top-navigation__logo">
            <strong class="top-navigation__name">ROOSH</strong><span class="u-color-roosh--600">.</span>
          </div>
          <div class="top-navigation__suffix">{{ $t('nav.provider_dashboard') }}</div>
        </div>

        <img class="aside-navigation__logo-img" alt="logo" src="@/assets/img/logo.svg" width="50" height="60"/>

      </div>

      <template v-if="showMenu  && active_provider_index !== null">

        <div class="aside-navigation__provider-switch">
          <template v-if="changingProvider">
            <div>
              <div class="aside-navigation__provider-switch-label">{{ $t('nav.switch_between_providers') }}</div>
              <d-form-select v-model="active_provider_index" @change="updateActiveProviderStorage" :options="providerSelectOptions" />
            </div>
            <button @click="changingProvider = false" class="aside-navigation__provider-change  aside-navigation__provider-change--stop">
              <i class="aside-navigation__provider-change-icon  material-icons">cancel</i>
            </button>
          </template>
          <template v-else>
            <div>
              <div class="aside-navigation__provider-switch-label">{{ $t('nav.current_providers') }}:</div>
              <div class="aside-navigation__provider-name">{{ activeProvider.name }}</div>
            </div>
            <button v-if="providers.length > 1" @click="changingProvider = true" class="aside-navigation__provider-change">
              <i class="aside-navigation__provider-change-icon  material-icons">settings</i>
            </button>
          </template>
        </div>

        <nav class="aside-navigation__nav">
          <ul class="aside-navigation__list">
            <li class="aside-navigation__list-item" v-for="item in items" :key="item.title">
              <router-link :to="{name: item.name}" class="aside-navigation__link" :class="{ 'is-active': isNamedRouteActive(item.name) }">
                <i class="aside-navigation__icon  sp-mr-2  material-icons">{{ item.icon }}</i>
                <span class="aside-navigation__text">{{ $t('nav.items.' + item.title) }}</span>
              </router-link>
            </li>
          </ul>
        </nav>

      </template>

    </aside>

    <div class="aside-navigation__shader" :class="{ 'is-open': $store.state.drawer }" @click="$store.commit('TOGGLE_SITE_NAV', !$store.state.drawer)"></div>
  </div>
</template>

<script>
import {mapProviderStateFields} from "~/store";

export default {
  name: "AsideNavigation",

  data: () => ({
    changingProvider: false,
    items: [
      {
        title: "statistics",
        icon: "bar_chart",
        name: "statistics"
      },
      /* { title: "settings", icon: "mdi-cogs", to: "/settings/single" }, */
      {
        title: "arrivals_departures",
        icon: "airplane_ticket",
        name: "orders.arrivalsDepartures"
      },
      {
        title: "orders",
        icon: "description",
        name: "orders.index"
      },
      // {
      //   title: "price_list",
      //   icon: "money",
      //   name: "pricelist"
      // },
      {
        title: "price_list",
        icon: "money",
        name: "servicePriceList"
      },
    ]
  }),

  computed: {
    showMenu() {
      return !this.activeRouteStartsWith('onBoarding.');
    },
    ...mapProviderStateFields([
      'active_provider_index',
      'providers',
      'providerSideBarOrders',
    ]),

    providerSelectOptions() {
      const options = [];

      this.providers.forEach((provider, index) => {
        options.push({
          value: index,
          text: provider.name
        });
      })

      return options;
    },
    activeProvider() {
      if(this.active_provider_index === null) return null
      return this.providers[this.active_provider_index]
    }
  },

  methods: {
    updateActiveProviderStorage: function (value) {
      window.localStorage.setItem('activeProviderIndex', value)
    }
  }
};
</script>