import Vuex from 'vuex';
import Vue from 'vue';
import {getField, updateField} from "vuex-map-fields";

Vue.use(Vuex);

export const providerState = {
  namespaced: true,
  state: {
    is_loaded: false,
    active_provider_index: null,
    providers: [],
    providerSideBarOrders: {
      orders_loaded: false,
      arrivals: [],
      departures: [],
    },
  },

  getters: {
    getField
  },

  mutations: {
    updateField
  },
}